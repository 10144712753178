'use client';

function Error() {
    return (
        <div>
            <h1>500 - Server-side error occurred!</h1>
        </div>
    );
}

export default Error;